@import "../../../../styles/styles";
.project-card{
  margin: .5rem;
  border-radius: 15px;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  transition: box-shadow 0.3s;
  background: $background-color;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fit-content;
  .project-title{
    font-size: 1.2rem;
    font-weight: bold;
    margin:0.2rem 0;
  }
  .project-description{
    white-space: pre-wrap;
    text-align: justify;
  }
  .project-year{
    color: $background-third-color;
  }
  .project-sources{
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
    .source{
      a{
        text-decoration: underline;
        color: $background-third-color;
        transition: color .3s;
        &:hover{
          color: $text-color;
        }
      }
    }
  }
  .project-tech-stacks{
    display: flex;
    gap: 0.3rem;
    flex-wrap: wrap;
    .project-tech{
      padding: 0.5rem;
      background-color: $background-fourth-color;
      width: fit-content;
      border-radius: 10px;
      color: $text-sidebar-color;
      font-size: 0.8rem;
      white-space: nowrap; 
      flex-wrap: wrap;   
    }
  }
  &:hover{
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  }
}
