.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader-img {
    max-width: 100px;
    max-height: 100px;
    width: 10%;
    height: 10%;
  }
}