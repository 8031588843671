@import "../../styles/styles";
.nav{
  padding: 1rem 0;
  border-top: 2px solid $background-third-color;
  position: fixed;
  width: 100%;
  transition: padding-top .5s, padding-bottom .5s, box-shadow .5s, background-color .5s;
  z-index: 9;
  .nav-content {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .wrapper{
      display: flex;
      align-items: center;
      .logo {
        font-family: 'Bonheur Royale', cursive;
        font-size: 5rem;
        font-weight: bold;
        transition: font-size .5s;
      }
      .link{
        font-family: 'Montserrat', sans-serif;
        color: $text-second-color;
        padding: .5rem;
        font-size: 1rem;
        margin: 1rem .5rem;
        text-align: center;
        transition: color .3s;
        &:hover{
          color: $text-color;
        }
      }
      .bar-icon{
        font-size: 1.5rem;
        line-height: 1.5rem;
        transition: font-size .5s;
      }
    }
  }
  &.scroll-70{
    padding: .3rem 0;
    box-shadow: rgb(68 68 68 / 20%) 0 0 20px;
    background-color: #F6F5F5;
    .nav-content{
      .wrapper{
        .logo{
          font-size: 3rem;
        }
      }
    }
  }
}

//mobile or tablet
@media only screen and (max-width: 1023px) {
  .nav{
    .nav-content {
      .wrapper{
        .logo{
          font-size: 3rem;
        }
      }
    }
    &.scroll-70{
      .nav-content{
        .wrapper{
          .logo{
            font-size: 2rem;
          }
        }
        .bar-icon{
          font-size: 1.3rem;
        }
      }
    }
  }
}