@import "../../styles/styles";
.sidenav{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $background-third-color;
  color: $background-second-color;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  overflow-y: scroll;
  z-index: 999;
  .links{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    margin: auto;
    padding: 3rem 0;
    .link{
      color: $text-second-color;
      padding: .5rem;
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin: 1rem;
      text-align: center;
    }
  }
  .close{
      position: absolute;
      top: 1rem;
      right: 10%;
      color: $text-second-color;
      font-size: 1.5rem;
    }
}