@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap');
@import "./styles/styles";
.container{
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding: 0 5%;
    box-sizing: border-box;
    color: $text-color;
}
a{
    text-decoration: none;
    color: $text-color;
}

@media only screen and (max-width: 1440px) {
    .container{
        max-width: 1024px;
    }
}
@media only screen and (max-width: 1023px) {
    .container{
        max-width: 768px;
    }
}
@media only screen and (max-width: 768px) {
    .container{
        max-width: 90%;
    }
}