@import "../../styles/styles";
.profile{
  box-sizing: border-box;
  .detail-me-holder{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .img-holder{
      width: 50%;
      max-width: 400px;
      .my-pic{
        border-radius: 3px;
        width: 100%;
        height: 100%;
      }
    }
    .text{
      font-family: 'Montserrat', sans-serif;
      font-size: 2rem;
      font-weight: 200;
      padding: 3rem;
      span{
        font-size: 1rem;
        font-weight: 600;
        border-left: 3px solid $text-color;
        padding: 3px 5px;
      }
    }
  }
}

//mobile or tablet
@media only screen and (max-width: 1024px) {
  .profile{
    .detail-me-holder{
      .text{
        font-size: 1.5rem;
        text-align: left;
        padding: 1rem;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .profile{
    .detail-me-holder{
      flex-direction: column;
      max-width: 400px;
      margin: 0 auto;
      .img-holder{
        width: 100%;
      }
      .text{
        font-size: 1.5em;
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 425px) {
  .profile{
    .detail-me-holder{
      .text{
        font-size: 1.3rem;
      }
    }
  }
}