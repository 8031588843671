@import "../../styles/styles";
.hero{
  padding: 3rem 0;
  .welcome-message{
    font-family: 'Montserrat', sans-serif;
    padding: 3rem 0;
    margin: auto;
    text-align: center;
    width: 50%;
    .name{
      font-size: 2.5rem;
      font-weight: bold;
      padding: 1rem;
    }
    .short-story{
      font-weight: 300;
    }
  }
  .social-media{
    display: flex;
    justify-content: center;
    .social-icon{
      font-size: 1.5rem;
      padding: 0.5rem;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: border .3s;
      border-bottom: 2px solid #113f6700;
      &:hover{
        border-bottom: 2px solid #113f67;
      }
    }
  }
}

//mobile or tablet
@media only screen and (max-width: 1023px) {
  .hero{
    .welcome-message {
      width: 100%;
      .name{
        font-size: 2rem;
        padding: .8rem;
      }
      .short-story{
      }
    }
  }
}