@import "../../styles/styles";
.project{
  color: $text-color;
  background-color: $background-fifth-color;
  padding: 3rem 0 3rem;
  font-family: "Montserrat", sans-serif;
  .title{
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
  }
  .select-wrapper{
    display: inline-block;
    margin: 1rem 0;
    padding: .5rem;
    border-radius: 15px;
    border: 1px solid $text-color;
    box-sizing: border-box;
    .project-selector{
      padding: .5rem 3.5rem .5rem .5rem;
      border: none;
      font-size: 1.3rem;
      font-weight: bold;
      color: $text-color;
      outline: none;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      background: url('../../assets/svg/down-arrow.svg') no-repeat 95% 50%;
      background-size: 1.5rem;
      cursor: pointer;
    }
    &:hover{
      box-shadow: 0 0 5px rgb(0 0 0 / 40%);
    }
  }
}

@media only screen and (max-width: 767px) {
  .project {
    .title{
      font-size: 1.5rem;
      width: 100%;
    }
    .select-wrapper{
      display: inline-block;
      width: 100%;
      .project-selector{
        width: inherit;
        font-size: 1rem;
      }
    }
  }
}