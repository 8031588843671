@import "../../styles/styles";
.about-website{
    font-family: 'Montserrat', sans-serif;
    padding: 10% 5%;
    .about-website-link, .about-website-link>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin: 0 auto;
        gap: 10px;
    }
    .text{
        text-align: center;
        white-space: pre-wrap;
        margin: 0;
        span{
            border-bottom: 1px solid transparent;
            transition: .3s;
            display: flex;
            align-items: center;
            text-decoration: underline transparent;

        }
        &:hover{
            span{
                text-decoration-color: $text-color;
            }
        }
    }
}