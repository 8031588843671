.project-collection{
  padding: 1rem 0;
  .card-deck{
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    margin: 0 -1rem;
  }

  .slick-prev:before, .slick-next:before{
    color: #113F67
  } 
  
  .button-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    .show-more-project-btn{
      border-radius: 5px;
      margin: 1rem;
      padding: 1rem;
      cursor: pointer;
      border: 1px solid #113F67;
      transition: background-color 0.3s;
      text-align: center;
      &.show{
        background-color: transparent;
        color: #113F67;
      }
      &.not-show{
        background-color: #113F67;
        color: #F6F5F5;
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .project-collection {
    .project-title{
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .project-collection{
    .button-wrapper{
      .show-more-project-btn{
        width: 100%;
        margin: 1rem 0;
      }
    }
  }
}